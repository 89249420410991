import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardActionArea, CardMedia, CardContent,
        CardActions, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/styles';

import useStyles from './styles';
import Tags from './Tags';
// import ViewPort from '../../../services/ViewPort';

import Title from '../../../components/Title'

import contentList from '../../../content/contentList';


export default function Articles() {
    
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();

    // const { width } = ViewPort();
    // const sm = 600;

    const [numContent, setNumContent] = useState(6);

    const [loadedContentList, setLoadedContentList] = useState('');
    useEffect(() => {
        setLoadedContentList(Object.fromEntries(Object.entries(contentList).slice(0,numContent)));
    }, [numContent]);


    
    return(
        <Box className={classes.general}>

            <Title
                    main='CONTEÚDO'
                    description='Acesse nossos materiais e domine a gestão financeira do seu negócio'
            >
                <p>
                    Do básico ao avançado, encontre tudo o que você precisa para dominar a gestão financeira!
                    Acesse artigos, vídeos com dicas práticas para o dia-a-dia e planilhas para análises completas.
                </p>
            </Title>

            <Box className={classes.container}>

                {
                    Object.entries(loadedContentList).map(([id, info]) => (

                        <Card
                                key={id}
                                className={classes.card}
                                sx={{ ':hover': { boxShadow: 20}}}
                        >
                            <CardActionArea
                                    className={classes.card_area}
                                    onClick={() => navigate(`/conteudo/${id}`) }
                            >
                                <CardMedia
                                    component='img'
                                    height='140'
                                    image={require('../../../content/images/' + info.image)}
                                    alt={id}
                                />
                                <CardContent>
                                    <Box className={classes.container_tags}>
                                        {info.tags.map((e,i) => 
                                            <Tags key={i} text={e} />
                                        )}
                                    </Box>
                                    <Typography gutterBottom variant='body1' component='h3' sx={{ fontWeight: 'bold' }} >
                                        {info.title}
                                    </Typography>
                                    <Typography variant='body2' sx={{ color: theme.colors.dark_gray }}>
                                        {info.short_description.substring(0,180) + '...'}
                                    </Typography>
                                    <p style={{ padding: '1em 0 0 0', margin: 0 }}>{info.date}</p>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button
                                    size='small'
                                    // color='secondary'
                                    sx={{ color: theme.colors.dark_gray, fontWeight: 'bold' }}
                                    onClick={() => navigate(`/conteudo/${id}`) }
                                >
                                    LER MAIS
                                </Button>
                            </CardActions>
                        </Card>

                    ))
                }

            </Box>

            {numContent < Object.keys(contentList).length &&
                (<Button
                    variant='outlined'
                    color='secondary'
                    onClick={() => setNumContent(numContent + 3)}
                    sx={{m: '0.3em'}}
                >
                    + CONTEÚDO
                </Button>)
            }
        </Box>
    );

}