import React, { useRef, Fragment } from 'react';

import FooterC from '../../components/FooterC';
import WhatsApp from '../../components/WhatsApp';

import Video from './Video';
import Argument from './Argument';
import Author from './Author';
// import Public from './Public';
import Clients from './Clients';
// import Stage from './Stage';
import Solutions from './Solutions';
import Walkthrough from './Walkthrough';
// import Foundation from './Foundation';
import Proposal from './Proposal';
import Benefits from './Benefits';
import Content from './Content';


export default function Retail() {

    //título da página
    document.title = 'Nosso Consultor';

    const proposal_view = useRef();
    const scrollToProposal = () => { proposal_view.current.scrollIntoView({ behavior: 'smooth' }) }

    const author_view = useRef();
    const scrollToAuthor = () => { author_view.current.scrollIntoView({ behavior: 'smooth' }) }

    const solutions_view = useRef();
    const scrollToSolutions = () => { solutions_view.current.scrollIntoView({ behavior: 'smooth' }) }

    const walkthrough_view = useRef();
    const scrollToWalkthrough = () => { walkthrough_view.current.scrollIntoView({ behavior: 'smooth' }) }

    const clients_view = useRef();
    const scrollToClients = () => { clients_view.current.scrollIntoView({ behavior: 'smooth' }) }

    const content_view = useRef();
    const scrollToContent = () => { content_view.current.scrollIntoView({ behavior: 'smooth' }) }

    return(
        <Fragment>

            <div ref={proposal_view}><Proposal source='varejo'/></div>
            <div ref={author_view}><Video /></div>
            <Author />
            <div ref={solutions_view}><Solutions scrollToProposal={scrollToProposal}/></div>
            <div ref={walkthrough_view}><Walkthrough scrollToProposal={scrollToProposal}/></div>
            <div ref={content_view}><Content /></div>
            <div ref={clients_view}><Clients /></div>
            <Argument />

            <Benefits scrollToProposal={scrollToProposal}/>
            
            <FooterC
                scrollToProposal={scrollToProposal}
                scrollToAuthor={scrollToAuthor}
                scrollToSolutions={scrollToSolutions}
                scrollToWalkthrough={scrollToWalkthrough}
                scrollToClients={scrollToClients}
                scrollToContent={scrollToContent}
            />

            <WhatsApp
                msg='Olá, gostaria de mais informações sobre a consultoria financeira.'
                popup_msg='Tem alguma dúvida? Envie um whatsapp!'
                time_between={55000}
                time_up={5000}
            />
        </Fragment>
    );

}