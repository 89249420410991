import { makeStyles } from '@mui/styles';

// import logo from '../../assets/company/logo.svg';

const useStyles = makeStyles(theme => ({ 

    general: {
        width: '100%',
        minHeight: '100vh',
        // marginTop: '5em',

        // backgroundImage: `url(${logo})`,
        // backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'center',
        // backgroundSize: 'cover',

        // [theme.breakpoints.up('sm')]: {
        //     position: 'relative',
        // },
    },

    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        width: '100%',
        height: '100%',
        minHeight: '100vh',
        paddingTop: '2em',
        paddingBottom: '1em',
        paddingInline: '1em',
        background: 'rgba(255,255,255,0.9)',

        [theme.breakpoints.up('sm')]: {
            paddingTop: '4em',
            paddingBottom: '3em',
        },
    },

    article_text: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        width: '100%',
        maxWidth: '1000px',
        height: '100%',
        minHeight: '100vh',
    },

    logo: {
        width: '140px',
        alignSelf: 'center',
        position: 'relative',
        top: '0',
        left: '0',
        paddingBottom: '1.5em',

        [theme.breakpoints.up('md')]: {
            width: '130px',
            position: 'fixed',
            top: '0',
            left: '0',
            margin: '1.5em 0 0 1.5em',
            paddingBottom: '0',
        },
    },
    
    contact: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: '4em',
        paddingBottom: '4em',

        [theme.breakpoints.up('sm')]: {
            paddingRight: '2em',
            flexDirection: 'row',
            paddingTop: '8em',
            paddingBottom: '6em',
        },
    },

    contact_title: {
        paddingRight: '0',
        paddingBottom: '1em',
        [theme.breakpoints.up('sm')]: {
            paddingRight: '2em',
            paddingBottom: '0',
        },
    },

    container_video: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        paddingTop: '1em',
        paddingBottom: '3em',
    },

    video: {
        height: '197px',
        width: '350px',

        boxShadow: '0 0.2em 0.4em 0 rgba(100, 100, 100, 0.2), 0 0.3em 1em 0 rgba(100, 100, 100, 0.19)',

        [theme.breakpoints.up('sm')]: {
            height: '400px',
            width: '712px',

            [theme.breakpoints.up('md')]: {
                height: '500px',
                width: '890px',
            },
        },
    },
    
    md_p: {
        fontSize: '20px',
    },

    md_li: {
        fontSize: '20px',
    },

    md_h2: {
        fontSize: '26px',
    },

    md_h3: {
        fontSize: '24px',
    },


}));
export default useStyles;